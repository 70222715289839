export const APIURL_PRODUCTION = 'https://api2.augmenttherapy.com';
export const APIURL_TESTING = 'https://at-admin-api-test.azurewebsites.net'; 
export var RUN_MODE = "production"; // "production" or "testing"

export const AdminVersion = "1.3.9";

export const ReportDaysPrevious: number = 45; //45;
export const chartType = "line"; //"area"; //"line"; //"column";
export const chartStack:boolean = false; //false; //true;
export const AppStoreURL:string = "https://apps.apple.com/us/app/arwell-pro/id6463829198";
export const ATnewsURL:string = "https://augmenttherapy.com/support/";
export const ATtherapistRemoteURL:string = "https://admin1.augmenttherapy.com/remote/index.html";
export const ATsupportSite:string = "https://augmenttherapy.com/providers/";
export const ATtelehealthinstall:string = "https://admin1.augmenttherapy.com//telehealth/atinstaller.msi";
export const ATuserguide:string = "https://augmenttherapy.com/wp-content/uploads/2023/04/Augment-Therapy-User-Guide-3.29.23.pdf";
export const ATexerciseGuide:string = "https://augmenttherapy.com/wp-content/uploads/2023/04/AT-Exercise-Reference-Guide-9.1.pdf";
export const ATsetupAndUseLink:string = "https://augmenttherapy.com/support/";
export const TimeZoneOptions: any = [
    {label:"Hawaiian (HST)",value:"Hawaiian Standard Time"},
    {label:"Pacific (PST)",value:"Pacific Standard Time"},
    {label:"Mountain (MST)",value:"Mountain Standard Time"},
    {label:"Central (CST)",value:"Central Standard Time"},
    {label:"Eastern (EST)",value:"Eastern Standard Time"},
    {label:"GMT Standard (GMT)",value:"GMT Standard Time"}
];
export const ATtaskBenefits: any = {
    // Upper Body
    "Arm Circles": ["Strength", "Bilateral Coordination", "Range of Motion", "Dynamic Balance", "Endurance", "Postural Stability", "Body Awareness", "Standing Endurance", ],
    "Shoulder Abduction": ["Range of Motion", "Dynamic Balance", "Postural Stability", "Body Awareness", "Standing Endurance", ],
    "Shoulder Abduction SS": ["Range of Motion", "Dynamic Balance", "Postural Stability", "Body Awareness", "Standing Endurance", ],
    "Shoulder Flexion": ["Range of Motion", "Postural Stability", "Body Awareness", "Standing Endurance", ],
    "Music Mayhem": ["Bilateral Coordination", "Dynamic Balance", "Endurance", "Postural Stability", "Body Awareness", "Motor Planning", "Standing Endurance", "Weight Shift", "Midline Crossing", "Lateral Movement", "Visual Discrimination", ],
    "Sit and Reach": ["Bilateral Coordination", "Range of Motion", "Dynamic Balance", "Static Balance", "Postural Stability", "Body Awareness", "Motor Planning", "Standing Endurance", "Weight Shift", "Midline Crossing", "Visual Discrimination",  ],
    // Lower Body
    "Hip Abduction": ["Strength", "Dynamic Balance", "Postural Stability", "Body Awareness", "Standing Endurance", "Weight Shift",  ],
    "Hip Extension": ["Strength", "Dynamic Balance", "Postural Stability", "Body Awareness", "Standing Endurance", "Weight Shift",  ],
    "Hop": ["Strength", "Bilateral Coordination", "Dynamic Balance",  ],
    "Jump": ["Strength", "Bilateral Coordination", "Dynamic Balance",  ],
    "Jump Forward": ["Strength", "Bilateral Coordination", "Dynamic Balance",  ],
    "Jump in Place": ["Strength", "Bilateral Coordination", "Dynamic Balance", "Motor Control",  ],
    "March in Place": ["Strength", "Bilateral Coordination", "Dynamic Balance", "Endurance", "Postural Stability", "Body Awareness", "Weight Shift",  ],
    "Mini Squats": ["Strength", "Dynamic Balance", "Endurance", "Postural Stability", "Body Awareness", "Motor Planning", "Standing Endurance",  ],
    "Puddle Jumper": ["Strength", "Bilateral Coordination", "Dynamic Balance", "Body Awareness", "Motor Control",  ],
    "Rocket Launcher": ["Strength", "Bilateral Coordination", "Dynamic Balance", "Endurance", "Postural Stability", "Body Awareness", "Motor Control", "Motor Planning", "Standing Endurance", "Weight Shift",  ],
    "Run in Place": ["Strength", "Bilateral Coordination", "Dynamic Balance", "Endurance", "Body Awareness", "Motor Planning",  ],
    "Seated Long Arc Quad": ["Strength", "Bilateral Coordination", "Range of Motion", "Static Balance", "Endurance", "Postural Stability", "Body Awareness", "Motor Control"],
    "Single Limb Stance": ["Strength", "Static Balance", "Endurance", "Postural Stability", "Body Awareness", "Motor Control", "Weight Shift",  ],
    "Standing Tolerance": ["Strength", "Static Balance", "Endurance", "Postural Stability", "Body Awareness", "Motor Control", ],
    // 3D Worlds
    "Candy": ["Bilateral Coordination", "Dynamic Balance", "Endurance", "Motor Planning", "Executive Functioning", "Cognitive Skills", ],
    "Snack Land": ["Bilateral Coordination", "Dynamic Balance", "Endurance", "Motor Planning", "Executive Functioning", "Cognitive Skills", ],
    "Castleton": ["Bilateral Coordination", "Dynamic Balance", "Endurance", "Motor Planning", "Executive Functioning", "Cognitive Skills", ],
    "Castle Quest": ["Bilateral Coordination", "Dynamic Balance", "Endurance", "Motor Planning", "Executive Functioning", "Cognitive Skills", ],
    "Flying": ["Bilateral Coordination", "Dynamic Balance", "Endurance", "Postural Stability", "Motor Planning", "Executive Functioning", "Cognitive Skills", ],
    "Nature Hike": ["Bilateral Coordination", "Dynamic Balance", "Endurance", "Motor Planning", "Executive Functioning", "Cognitive Skills", ],
    "Star Catcher": ["Bilateral Coordination", "Dynamic Balance", "Endurance", "Motor Planning", "Executive Functioning", "Cognitive Skills", ],
    "Town": ["Bilateral Coordination", "Dynamic Balance", "Endurance", "Motor Planning", "Executive Functioning", "Cognitive Skills", ],
    // Sports Games
    "Ball Pit": ["Range of Motion", "Dynamic Balance", "Static Balance", "Endurance", "Postural Stability", "Body Awareness", "Standing Endurance", "Weight Shift", "Executive Functioning", ],
    "Bowling": ["Bilateral Coordination", "Dynamic Balance", "Motor Control", "Motor Planning", "Standing Endurance", "Executive Functioning", ],
    "Color Ball": ["Bilateral Coordination", "Range of Motion", "Dynamic Balance", "Postural Stability", "Body Awareness", "Motor Control", "Motor Planning", "Standing Endurance", "Weight Shift", "Executive Functioning", "Midline Crossing", "Lateral Movement", "Visual Motor", "Visual Discrimination", "Cognitive Skills", ],
    "IWall": ["Bilateral Coordination", "Dynamic Balance", "Endurance", "Body Awareness", "Motor Planning", "Standing Endurance", "Weight Shift", "Executive Functioning", "Midline Crossing", "Lateral Movement", ],
    "Kick Ball": ["Bilateral Coordination", "Dynamic Balance", "Postural Stability", "Body Awareness", "Motor Control", "Motor Planning", "Standing Endurance", "Weight Shift", "Executive Functioning", ],
    "Obstacle Course": ["Bilateral Coordination", "Dynamic Balance", "Endurance", "Body Awareness", "Motor Control", "Motor Planning", "Standing Endurance", "Weight Shift", "Executive Functioning", "Lateral Movement", ],
    "Pickleball": ["Bilateral Coordination", "Dynamic Balance", "Body Awareness", "Motor Planning", "Standing Endurance", "Weight Shift", "Executive Functioning", ],
    "Punch Ball": ["Bilateral Coordination", "Dynamic Balance", "Endurance", "Body Awareness", "Motor Planning", "Standing Endurance", "Executive Functioning", ],
    "PowerboardLevelSelect": ["Bilateral Coordination", "Dynamic Balance", "Static Balance", "Postural Stability", "Body Awareness", "Motor Control", "Motor Planning", "Standing Endurance", "Weight Shift", "Executive Functioning", "Cognitive Skills", ],
    "Skiing": ["Bilateral Coordination", "Dynamic Balance", "Static Balance", "Postural Stability", "Body Awareness", "Motor Control", "Motor Planning", "Standing Endurance", "Weight Shift", "Executive Functioning", ],
    "Surfing": ["Bilateral Coordination", "Dynamic Balance", "Static Balance", "Postural Stability", "Body Awareness", "Motor Control", "Motor Planning", "Standing Endurance", "Weight Shift", "Executive Functioning", "Cognitive Skills", ],
    // OT Activities
    "Air Writing": ["Bilateral Coordination", "Range of Motion", "Dynamic Balance", "Static Balance", "Postural Stability", "Body Awareness", "Motor Control", "Motor Planning", "Standing Endurance", "Weight Shift", "Executive Functioning", "Midline Crossing", "Cognitive Skills", ],
    "Air Writing SS": ["Bilateral Coordination", "Range of Motion", "Dynamic Balance", "Static Balance", "Postural Stability", "Body Awareness", "Motor Control", "Motor Planning", "Standing Endurance", "Weight Shift", "Executive Functioning", "Midline Crossing", "Cognitive Skills", ],
    "Memory Cards": ["Range of Motion", "Dynamic Balance", "Static Balance", "Postural Stability", "Body Awareness", "Motor Control", "Motor Planning", "Standing Endurance", "Executive Functioning", "Midline Crossing", "Cognitive Skills", ],
    "Memory Sesame": ["Range of Motion", "Dynamic Balance", "Static Balance", "Postural Stability", "Body Awareness", "Motor Control", "Motor Planning", "Standing Endurance", "Executive Functioning", "Midline Crossing", "Cognitive Skills", ],
    "Bowl of Cereal": ["Range of Motion", "Dynamic Balance", "Static Balance", "Postural Stability", "Body Awareness", "Motor Control", "Motor Planning", "Standing Endurance", "Executive Functioning", "Midline Crossing", "Cognitive Skills", ],
    "Brush Teeth": ["Range of Motion", "Dynamic Balance", "Static Balance", "Postural Stability", "Body Awareness", "Motor Control", "Motor Planning", "Standing Endurance", "Executive Functioning", "Midline Crossing", "Cognitive Skills", ],
    "Casual Clothing, Female": ["Range of Motion", "Dynamic Balance", "Static Balance", "Postural Stability", "Body Awareness", "Motor Control", "Motor Planning", "Standing Endurance", "Executive Functioning", "Midline Crossing", "Cognitive Skills", ],
    "Daily Clothing, Female": ["Range of Motion", "Dynamic Balance", "Static Balance", "Postural Stability", "Body Awareness", "Motor Control", "Motor Planning", "Standing Endurance", "Executive Functioning", "Midline Crossing", "Cognitive Skills", ],
    "Formal Clothing, Female": ["Range of Motion", "Dynamic Balance", "Static Balance", "Postural Stability", "Body Awareness", "Motor Control", "Motor Planning", "Standing Endurance", "Executive Functioning", "Midline Crossing", "Cognitive Skills", ],
    "Fried Eggs": ["Range of Motion", "Dynamic Balance", "Static Balance", "Postural Stability", "Body Awareness", "Motor Control", "Motor Planning", "Standing Endurance", "Executive Functioning", "Midline Crossing", "Cognitive Skills", ],
    "Hamburgers": ["Range of Motion", "Dynamic Balance", "Static Balance", "Postural Stability", "Body Awareness", "Motor Control", "Motor Planning", "Standing Endurance", "Executive Functioning", "Midline Crossing", "Cognitive Skills", ],
    "Laundry": ["Range of Motion", "Dynamic Balance", "Static Balance", "Postural Stability", "Body Awareness", "Motor Control", "Motor Planning", "Standing Endurance", "Executive Functioning", "Midline Crossing", "Cognitive Skills", ],
    "PB&J Sandwich": ["Range of Motion", "Dynamic Balance", "Static Balance", "Postural Stability", "Body Awareness", "Motor Control", "Motor Planning", "Standing Endurance", "Executive Functioning", "Midline Crossing", "Cognitive Skills", ],
    // Wellness
    "Avatar Guitar": ["Bilateral Coordination", "Dynamic Balance", "Postural Stability", "Body Awareness", "Standing Endurance", ],
    "Snow Globe": ["Dynamic Balance", "Static Balance", "Postural Stability", "Body Awareness", "Standing Endurance", "Weight Shift", ],
    "Yoga": ["Strength", "Bilateral Coordination", "Range of Motion", "Dynamic Balance", "Static Balance", "Postural Stability", "Body Awareness", "Motor Control", "Motor Planning", ],
};

export const ATadlTasks = ["Bowl of Cereal","Brush Teeth","Casual Clothing, Female","Daily Clothing, Female","Formal Clothing, Female","Fried Eggs","Hamburgers","Laundry","PB&J Sandwich"];

export const ATtaskDetailStats = {
    columns: [
      { field: 'startTime', title: 'Start Time', shortTitle: 'Started' },
      { field: 'totalRepsDurationL', title: 'Repetitions Duration (Left)', shortTitle: 'Reps Duration (L)' },
      { field: 'totalRepsDurationR', title: 'Repetitions Duration (Right)', shortTitle: 'Reps Duration (R)' },
      { field: 'totalRepsDuration', title: 'Repetitions Duration (Total)', shortTitle: 'Reps Duration (Total)' },
      { field: 'mpm', title: 'Estimated Movements Per Minute', shortTitle: 'Estimated movements per minute' },
      { field: 'maxRepDurationL', title: 'Max Repetitions Duration (Left)', shortTitle: 'Max Reps Duration (L)' },
      { field: 'maxRepDurationR', title: 'Max Repetitions Duration (Right)', shortTitle: 'Max Reps Duration (R)'},
      { field: 'maxRepDuration', title: 'Max Repetitions Duration (Total)', shortTitle: 'Max Reps Duration (Total)' },
      { field: 'restTime', title: 'Rest Time', shortTitle: 'Rest' },
      { field: 'distance', title: 'Distance (Inches)', shortTitle: 'Distance (In.)' },
      { field: 'height', title: 'Height (Inches)', shortTitle: 'Height (In.)' },
      { field: 'taskSteps', title: 'Steps', shortTitle: 'Steps'},
      { field: 'avgAngleL', title: 'Average Angle (Left)', shortTitle: 'Average Angle (L)' },
      { field: 'avgAngleR', title: 'Average Angle (Right)', shortTitle: 'Average Angle (R)' },
      { field: 'maxAngleL', title: 'Max Angle (Left)', shortTitle: 'Max Angle (L)' },
      { field: 'maxAngleR', title: 'Max Angle (Right)', shortTitle: 'Max Angle (R)' },
    ]
  };